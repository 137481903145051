(function()
{
	'use strict';

	angular.module('app', [ 'atsc', 'crossyn', 'zxcvbn', 'ngMaterial', 'ngResource' ]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ApiClientApiController', ['$scope', '$mdDialog', 'LookupService', '$parse', '$element', function($scope, $mdDialog, LookupService, $parse, $element)
	{
		var controller																									= this;
		var formController																								= $element.attr('data-formcontroller');

		$scope.toggleCheckboxes = function()
		{
			if($scope.$parent[formController].formData.inherit_parent_scopes)
			{
				$element.find('.scopes').prop('disabled', true);

				if ($scope.parentAppScopes != undefined)
				{
					for(var i = 0; i < $scope.parentAppScopes.length; i++)
					{
						$scope.$parent[formController].application_scopes[$scope.parentAppScopes[i]]					= true;
					}
				}

				if ($scope.parentAppUserScopes != undefined)
				{
					for(var i = 0; i < $scope.parentAppUserScopes.length; i++)
					{
						$scope.$parent[formController].application_user_scopes[$scope.parentAppUserScopes[i]]			= true;
					}
				}
			}
			else
			{
				$element.find('.scopes').prop('disabled', false);

				for (var application_scope in $scope.$parent[formController].application_scopes)
				{
					$scope.$parent[formController].application_scopes[application_scope]								= false;

					if($scope.childAppScopes != null && $scope.childAppScopes.includes(application_scope))				{ $scope.$parent[formController].application_scopes[application_scope] = true; }
				}

				for (var application_user_scope in $scope.$parent[formController].application_user_scopes)
				{
					$scope.$parent[formController].application_user_scopes[application_user_scope]						= false;

					if($scope.childAppUserScopes != null && $scope.childAppUserScopes.includes(application_user_scope))	{ $scope.$parent[formController].application_user_scopes[application_user_scope] = true; }
				}
			}
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ApiClientController', ['$scope', '$mdDialog', 'LookupService', '$parse', '$element', function($scope, $mdDialog, LookupService, $parse, $element)
	{
		var controller															= this;
		var formController														= $element.attr('data-formcontroller');

		$scope.showPwd = function()
		{
			var pwd																= $element.find('.pwd')[0];

			if (pwd.type === "password")										{ pwd.type = "text"; }
			else																{ pwd.type = "password"; }
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ApiVersionController', ['$scope', '$mdDialog', 'LookupService', '$parse', '$element', function($scope, $mdDialog, LookupService, $parse, $element)
	{
		var controller															= this;
		var formController														= $element.attr('data-formcontroller');

		$scope.$watch(formController + '.applicationSpecURI', function(newValue)
		{
			try
			{
				if (newValue)													{ swagger_visualize($element.find('#application-viz'), newValue); }
			}
			catch (ex)															{ /* no problem */ }
		});

		$scope.$watch(formController + '.applicationUserSpecURI', function(newValue)
		{
			try
			{
				if (newValue)													{ swagger_visualize($element.find('#applicationUser-viz'), newValue); }
			}
			catch (ex)															{ /* no problem */ }
		});
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ContractController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', function($scope, $window, $q, $http, $parse, $mdDialog, $element)
	{
		var controller																= this;
		var url																		= $window.location.href.right('#');

		controller.approve = function()
		{
			$http({
				url: url + '/approve',
				method: 'post',
			}).then(function(response)
			{
				if (response.data.error && response.data.error.message)
				{
					var msg															= response.data.error.message;

					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/error'))
							.textContent(msg)
							.ok('OK')
					).then(function()
					{
						reload();
					});
				}
				else
				{
					$mdDialog.show
					(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/message'))
							.textContent("Contract is active")
							.ok('OK')
					).then(function()
					{
						reload();
					});
				}
			});
		};

		var reject = function()
		{
			$http({
				url: url + '/reject',
				method: 'post'
			}).then(function(response)
			{
				if (response.data.error && response.data.error.message)
				{
					var msg															= response.data.error.message;

					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.textContent(msg)
							.ok('OK')
					).then(function()
					{
						reload();
					});
				}
				else
				{
					window.location.replace('/contracts');
				}
			});
		};

		controller.reject = function()
		{
			$mdDialog.show(
				$mdDialog.confirm()
					.title(translate('keyword/confirmation'))
					.textContent(translate("contract/reject/text"))
					.ok(translate('keyword/yes'))
					.cancel(translate('keyword/no'))
			).then(function()
			{
				reject()
			});
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DriverRegistrationListController', ['$scope', '$mdDialog', '$http', '$element', function($scope, $mdDialog, $http, $element)
	{
		var controller										= this;

		controller.state									= 'normal';

		controller.setState = function(newState)
		{
			controller.state = newState;
		};

		$scope.$on('table.onPerformAction', function(e, action, row, tableController, originalEvent)
		{
			switch (action)
			{
				case 'invite':
				{
					controller.rowId						= row._id;
					controller.email						= row.email;
					controller.state						= 'email-templates';

					$scope.$apply();

					break;
				}
			}
		});

		controller.sendInviteSingle = function()
		{
			if (controller.email != null && controller.email != '')
			{
				var data = {
					email: controller.email,
					firstName: controller.firstname,
					middleName: controller.middlename,
					lastName: controller.lastname,
					gender: controller.gender,
					number: controller.number,
					birthDate: controller.birthdate,
					country: controller.country,
					language: controller.language
				}
				$http({
							url: '/driver-registrations/invite',
							method: 'post',
							headers: { 'Content-Type': 'application/json' },
							data: data
						}).then(function(response)
						{
							if (response.data.error)
							{
								$mdDialog.show(
										$mdDialog.alert()
											.clickOutsideToClose(true)
											.title(response.data.error.title || translate('keyword/error'))
											.textContent(response.data.error.message)
											.ok('OK')
									);
							}
							else
							{
								$mdDialog.hide().then(function()
								{
									$mdDialog.show(
											$mdDialog.alert()
											.clickOutsideToClose(true)
											.title(translate('driver/invite'))
											.textContent(translate('invite/sent'))
											.ok('OK')
									).then(function()
									{
										controller.state = 'normal';
									});
								});
							}
						}, function(response)
						{
							$mdDialog.show(
								$mdDialog.alert()
									.clickOutsideToClose(true)
									.title(translate('server/errortitle'))
									.textContent(translate('server/errortext'))
									.ok('OK')
							);
						});
			}
			else{
				$mdDialog.hide().then(function()
								{
									$mdDialog.show(
											$mdDialog.alert()
												.clickOutsideToClose(true)
												.title(translate('driver/invite/missing-fields'))
												.textContent(translate('driver/invite/missing-fields/content'))
												.ok('OK')
										);
								});
			}
		};

		controller.sendInvite = function()
		{
			if ($scope.$$childHead && $scope.$$childHead.childTableControllers && $scope.$$childHead.childTableControllers.length)
			{
				for (var i = 0; i < $scope.$$childHead.childTableControllers.length; i = i + 1)
				{
					if ($scope.$$childHead.childTableControllers[i].id == 'email-templates')
					{
						var table									= $scope.$$childHead.childTableControllers[i];

						table.getSelectedRowIDs().then(function(ids)
						{
							if (ids.length > 0)
							{
								$http({
									url: '/driver-registrations/' + controller.rowId + '/invite',
									method: 'post',
									headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
									data: $.param({'email_template_id': ids[0], 'email': controller.email})
								}).then(function(response)
								{
									if (response.data.error)
									{
										$mdDialog.show(
												$mdDialog.alert()
													.clickOutsideToClose(true)
													.title(response.data.error.title || translate('keyword/error'))
													.textContent(response.data.error.message)
													.ok('OK')
											);
									}
									else if (response.data.newURL)
									{
										$mdDialog.hide().then(function()
										{
											$mdDialog.show(
													$mdDialog.alert()
														.clickOutsideToClose(true)
														.title(translate('driver/invite'))
														.textContent(translate('invite/sent'))
														.ok('OK')
												).then(function()
												{
													window.location.replace(response.data.newURL);
												});
										});
									}
									else
									{
										$mdDialog.hide().then(function()
										{
											$mdDialog.show(
													$mdDialog.alert()
													.clickOutsideToClose(true)
													.title(translate('driver/invite'))
													.textContent(translate('invite/sent'))
													.ok('OK')
											).then(function()
											{
												controller.state = 'normal';
											});
										});
									}
								}, function(response)
								{
									$mdDialog.show(
										$mdDialog.alert()
											.clickOutsideToClose(true)
											.title(translate('server/errortitle'))
											.textContent(translate('server/errortext'))
											.ok('OK')
									);
								});
							}
							else
							{
								$mdDialog.show(
									$mdDialog.alert()
										.clickOutsideToClose(true)
										.title(translate('sp-invites/resend'))
										.textContent(translate('sp-invites/resendtext'))
										.ok('OK')
								);
							}
						});

						return;
					}
				}
			}
		};

		var tableControllers												= [];

		$scope.$on('list.tableData', function(event, data) { tableControllers.push(data); });

		controller.deleteRegistrations = function()
		{
			for (var i = 0; i < tableControllers.length; i++)
			{
				if (tableControllers[i].queryid == 'driver_registrations')
				{
					tableControllers[i].getSelectedRowIDs().then(function(ids)
					{
						if (ids && ids.length > 0)
						{
							$mdDialog.show(
								$mdDialog.confirm()
									.title(translate('keyword/confirm'))
									.htmlContent(translate('registrations-delete/confirm/text', ids.length))
									.ok(translate('keyword/yes'))
									.cancel(translate('keyword/no'))
							).then(function()
							{
								$http({
									url: '/driver-registrations/delete',
									method: 'post',
									data: {'ids' : ids }
								}).then(function(response)
								{
									tableControllers[i].reload(false);
								});
							});
						}
					});

					return;
				}
			}
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DriverVehicleConnectionController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller															= this;
		var id																	= $element.attr('data-id');

		var refresh = function()
		{
			document.location.href 												= document.location.href.replace("/edit", "")
		};

		$scope.format = function(d)
		{
			var format															= moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');

			return d ? moment(d).format(format) : '';
		};

		var DialogController = function($scope, $mdDialog)
		{
			$scope.ok = function()
			{
				$mdDialog.hide();
			};
		};

		DialogController.$inject												= ["$scope", "$mdDialog" ];

		$scope.showInfo = function(ev, url)
		{
			$mdDialog.show(
			{
				controller: DialogController,
				templateUrl: url,
				parent: angular.element(document.body),
				targetEvent: ev,
				clickOutsideToClose: true
			});
		};

		controller.toggleEdit = function()
		{
			document.location.href 												= document.location.href + "/edit";
		}

		controller.submit = function(drivervehicleserviceid)
		{
			$http({
				url:  '/drivers/' + document.location.href.match('drivers/(.*?)/vehicles')[1] + '/vehicles/'+ drivervehicleserviceid +  '/change-consent',
				method: 'post',
				data: $scope.datasharingTypes
			}).then(function(response)
			{
				if (response.data.error && response.data.error.message)
				{
					var msg														= response.data.error.message;

					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.textContent(msg)
							.ok('OK')
					).then(function()
					{
						refresh();
					});
				}
				else
				{
					$timeout(function(){ refresh(); document.location.reload(); }, 500);
				}
			});
		};

		$scope.$parent.formController1.close = function()
		{
			document.location.href											= '/drivers/' + document.location.href.match('drivers/(.*?)/vehicles')[1];
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('VehicleOdometerController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller															= this;

		controller.submit = function(id)
		{
			var odometer														= controller.odometerUnit == 'miles' ? Math.round(controller.odometer * 1.609) : controller.odometer;
			controller.odometer													= odometer;
			controller.odometerUnit												= 'km';

			if (!odometer)
			{
				$mdDialog.show(
						$mdDialog.alert()
						.clickOutsideToClose(true)
						.title(translate('keyword/error'))
						.htmlContent("<p>" + translate('odometer/error') + "</p>")
						.ok('OK')
				);
			}
			else
			{
				$http({
					url:  '/vehicles/'+ id + '/odometer',
					method: 'post',
					data: controller
				}).then(function(response)
				{
					if (response.data.error && response.data.error.message)
					{
						var msg													= response.data.error.message;

						$mdDialog.show(
							$mdDialog.alert()
								.clickOutsideToClose(true)
								.title(translate('keyword/error'))
								.htmlContent(msg)
								.ok('OK')
						).then(function()
						{
							refresh();
						});
					}
					else
					{
						document.location.href									= '/vehicles/'+ id;
					}
				});
			}
		};

		controller.close = function()
		{
			history.back();
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('VehicleMonitorController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller = this;
		var mainDeviceMap;
		var backupDeviceMap;
		var mainLat;
		var mainLon;
		var backupLat;
		var backupLon;
		var routeNotShared;

		var backupIcon = L.icon({
            iconUrl: 'images/marker-icon-grey.png',
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -36],
                shadowUrl: 'images/marker-shadow.png',
                shadowSize: [41, 41],
                shadowAnchor: [13, 41]
         });

		var mainIcon = L.icon({
            iconUrl: 'images/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -36],
                shadowUrl: 'images/marker-shadow.png',
                shadowSize: [41, 41],
                shadowAnchor: [13, 41]
         });

		setTimeout(function ()
    		{
		        if (!routeNotShared)
		            {
		            mainDeviceMap = L.map('mainDeviceMap').setView([51.55551, 5.0913], 16);
	                backupDeviceMap = L.map('backupDeviceMap').setView([51.55551, 5.0913], 16);

	                L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
	                    attribution: 'Map data & Imagery &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	                    maxZoom: 18,
	                    tileSize: 512,
	                    zoomOffset: -1,
	                }).addTo(mainDeviceMap);

	                L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
	                    attribution: 'Map data & Imagery &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	                    maxZoom: 18,
	                    tileSize: 512,
	                    zoomOffset: -1,
	                }).addTo(backupDeviceMap);

	                L.Icon.Default.imagePath='./images/';

	                setMainMapValues();
	                setBackupMapValues();
		            }

    		}, 1000
		);

		controller.setLatLons = function (mainLatString, mainLonString, backupLatString, backupLonString, routeNotSharedString)
		{
		    routeNotShared = routeNotSharedString;
		    if (!routeNotShared)
		    {
		        if (mainLatString) mainLat = parseFloat(mainLatString.replace(',', '.'));
	            if (mainLonString) mainLon = parseFloat(mainLonString.replace(',', '.'));
	            if (backupLatString) backupLat = parseFloat(backupLatString.replace(',', '.'));
	            if (backupLonString) backupLon = parseFloat(backupLonString.replace(',', '.'));
		    }

		}

		function setMainMapValues()
		{
		    if (mainLat && mainLon && mainDeviceMap)
		    {
		        var mainPopup = L.popup({
		            autoClose: false,
		            closeButton: false
		        }).setContent('main device');

		        mainDeviceMap.setView([mainLat, mainLon], 14);
		        L.marker([mainLat, mainLon], { icon: mainIcon, zIndexOffset: 40000 }).addTo(mainDeviceMap).bindPopup(mainPopup);

		        if (backupLat && backupLon)
	            {
		            var line = [[mainLat, mainLon], [backupLat, backupLon]];

		            var backupPopup = L.popup({
		                autoClose: false,
	                    closeButton: false
	                }).setContent('backup device');

		            L.marker([backupLat, backupLon], { icon: backupIcon }).addTo(mainDeviceMap).bindPopup(backupPopup);
		            L.polyline(line, {color: 'black'}).addTo(mainDeviceMap);
	            }
		    }
		}

		function setBackupMapValues()
        {
            if (backupLat && backupLon && backupDeviceMap)
            {
                var backupPopup = L.popup({
                    autoClose: false,
                    closeButton: false
                }).setContent('backup device');

                backupDeviceMap.setView([backupLat, backupLon], 14);
                L.marker([backupLat, backupLon], { icon: backupIcon, zIndexOffset: 40000 }).addTo(backupDeviceMap).bindPopup(backupPopup);

                if (mainLat && mainLon)
                {
                    var line = [[backupLat, backupLon], [mainLat, mainLon]];

                    var mainPopup = L.popup({
                        autoClose: false,
                        closeButton: false
                    }).setContent('main device');

                    L.marker([mainLat, mainLon], { icon: mainIcon }).addTo(backupDeviceMap).bindPopup(mainPopup);
                    L.polyline(line, {color: 'black'}).addTo(backupDeviceMap);
                }
            }
        }

		controller.acceptDistance = function(id, vehicleId, value, description)
        {
			$(window).off('beforeunload');
            $http({
                url: '/vehicle-monitoring/update',
                method: 'post',
                data:{
                    type: 'acceptDistance',
                    id: id,
                    vehicle_id: vehicleId,
                    value: value,
                    description: description
                }
            }).then(function(response)
            {
                window.location.reload();
            });
        }

		controller.updateRecord = function(id)
		{
			$(window).off('beforeunload');
		    $http({
                url: '/vehicle-monitoring/refresh',
                method: 'post',
                data:{
                    id: id
                }
            }).then(function(response)
            {
                if (response.data.error)
                {
                    if (response.data.error.error && response.data.error.error === 'wait')
                    {
                        $mdDialog.show(
                                $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .title(translate('keyword/error'))
                                    .htmlContent(translate('vehicle_monitor/wait-minute'))
                                    .ok('OK'));
                    }
                    else if (response.data.error.error && response.data.error.error === 'no-device-access')
                    {
                        $mdDialog.show(
                                $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .title(translate('keyword/error'))
                                    .htmlContent(translate('vehicle_monitor/no-device-access'))
                                    .ok('OK'));
                    }
                    else if (response.data.error.error)
                    {
                        $mdDialog.show(
                                $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .title(translate('keyword/error'))
                                    .htmlContent(translate(response.data.error.error))
                                    .ok('OK'));
                    }
                    else
                    {
                        $mdDialog.show(
                                $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .title(translate('keyword/error'))
                                    .htmlContent(translate('errors/E10001'))
                                    .ok('OK'));
                    }


                }
                else
                {
                   window.location.reload();
                }
            });
		}

		controller.acceptTimestamp = function(id, vehicleId, value, isBackup, description)
        {
			$(window).off('beforeunload');
            $http({
                url: '/vehicle-monitoring/update',
                method: 'post',
                data:{
                    type: 'acceptTimestamp',
                    id: id,
                    vehicle_id: vehicleId,
                    value: value,
                    isBackup: isBackup,
                    description: description
                }
            }).then(function(response)
            {
               window.location.reload();
            });
        }

        controller.getvehicleMonitorColor = function(value, accepted, isDistance)
        {
            var currentTime                                                                 = new Date().getTime();
            if (accepted && new Date(accepted).getTime() > currentTime)
            {
                return "{'background-color':'#D3D3D3'}"
            }

           if (isDistance)
            {
                if (value != '' && value < 500)
                {
                    return "{'background-color':'#90EE90'}";
                }
                else if (value  != '' && value < 1500)
                {
                    return "{'background-color':'#ffff66'}";
                }
                else
                {
                    return "{'background-color':'#ff4d4d','color':'white'}";
                }
            }

            // gets difference in hours
            var difference = (currentTime - new Date(value).getTime()) / 3600000;

            if (difference < 48)
            {
                return "{'background-color':'#90EE90'}";
            }
            else if (difference < 96)
            {
                return "{'background-color':'#ffff66'}";
            }
            else
            {
                return "{'background-color':'#ff4d4d','color':'white'}";
            }
        }

        controller.showBackup = function(value)
        {
            if (value == null || value.length == 0)
            {
                return false;
            }

            return true;
        }

        controller.showOptions = function(value, accepted, isDistance)
        {
            var value = controller.getvehicleMonitorColor(value, accepted, isDistance)
            if (value == "{'background-color':'#90EE90'}" || value == "{'background-color':'#D3D3D3'}")
                {
                return false;
                }
            else
                { return true; }
        }

        controller.showAccepted = function(value, accepted, isDistance)
        {
            var value = controller.getvehicleMonitorColor(value, accepted, isDistance)

            if (value === "{'background-color':'#D3D3D3'}")
                {
                return true;
                }
            else
                { return false; }
        }
	}]);
})();

(function()
{
    'use strict';
    angular.module('app').controller('VehicleMonitoringController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
    {
        var controller = this;
        controller.pagesCount = 1;
        controller.pagesLimit = 25;
        controller.ordering = 'main_last_gps_arrival_datetime';
        controller.orderingType = 'DESC';
        controller.pageNumber = 1;
        controller.maxCount = 1;
        controller.minimalize = false;
        controller.hideBackup = false;
        controller.showOnlyRed = true;
        controller.headers = null;
        controller.filter = '';

        controller.createExport = function()
		{
			$http({
				url: '/exports/create-vehicle-monitor-export',
				method: 'post',
				data: {}
			}).then(function(response)
			{
				if (response.data.error)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(false)
							.title('Export')
							.htmlContent(translate(response.data.error.error))
							.ok('OK')
					)
				}
				else
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title('Export')
							.htmlContent('Item added to queue')
							.ok('OK')
					)
				}
			});
		};

        controller.resetPage = function()
        {
            controller.pageNumber = 1;
        }

        controller.toggleMinimalize = function()
        {
            controller.hideBackup = false;
            localStorage.setItem('vehicleMonitorMinimalize', controller.minimalize)
            localStorage.setItem('vehicleMonitorHideBackup', controller.hideBackup)
            window.location.reload();
        }
        controller.toggleHideBackup = function()
        {
            controller.minimalize = false;
            localStorage.setItem('vehicleMonitorHideBackup', controller.hideBackup)
            localStorage.setItem('vehicleMonitorMinimalize', controller.minimalize)
            window.location.reload();
        }


        controller.filterTable = function()
        {
            controller.pageNumber = 1;
            controller.getMaxCount();
            controller.loadTable();
        }
        controller.getMaxCount = function()
        {
            if (controller.pagesLimit && document.location.href.endsWith("/vehicle-monitoring"))
            {
                $http({
                    url: '/vehicle-monitoring/get-max',
                    method: 'post',
                    data: {
                        limit: controller.pagesLimit,
                        filter: controller.filter,
                        showOnlyRed: controller.showOnlyRed
                    }
                }).then(function(response)
                {
                    controller.pagesCount = response.data.data.items[0].pages
                    controller.maxCount = response.data.data.items[0].count
                });
            }
        }
        controller.showBackupHeaders = function ()
        {
            return controller.hideBackup
        }
        controller.showHeaders = function()
        {

            if (controller.minimalize || controller.hideBackup)
                {
                return false
                }
            return true;
        }
        controller.showMinimalizeHeaders = function ()
        {
            return controller.minimalize
        }
        controller.nextPage = function()
        {
            controller.pageNumber =  controller.pageNumber + 1;
            controller.loadTable();
        }
        controller.previousPage = function()
        {
            controller.pageNumber =  controller.pageNumber - 1;
            controller.loadTable();
        }
        controller.loadTable = function()
        {
            if (controller.pagesLimit && document.location.href.endsWith("/vehicle-monitoring"))
            {
                localStorage.setItem('vehicleMonitorPageNumber', controller.pageNumber)
                localStorage.setItem('vehicleMonitorPageLimit', controller.pagesLimit)
                localStorage.setItem('vehicleMonitorFilter', controller.filter)
                localStorage.setItem('vehicleMonitorOrdering', controller.ordering)
                localStorage.setItem('vehicleMonitorOrderType', controller.orderType)
                localStorage.setItem('vehicleMonitorShowOnlyRed', controller.showOnlyRed)

                $http({
                    url: '/vehicle-monitoring/get-list',
                    method: 'post',
                    data: {
                        page: controller.pageNumber,
                        limit: controller.pagesLimit,
                        filter: controller.filter,
                        ordering: controller.ordering,
                        orderBy: controller.orderingType,
                        showOnlyRed: controller.showOnlyRed
                    }
                }).then(function(response)
                {
                    if (response.data.error)
                    {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(translate('keyword/error'))
                                .htmlContent(translate('keyword/error'))
                                .ok('OK'));
                    }
                    else
                    {
                        var itemList = response.data.data.items[0];
                        var table = document.getElementById('table');
                        if (controller.headers == null)
                        {
                            controller.headers = table.innerHTML;
                        }


                        table.innerHTML = controller.headers;

                        var i = 0;
                        for (; i < itemList.length; i++)
                        {
                            var tr = document.createElement('tr');
                            var td = document.createElement('td');
                            td.innerHTML = itemList[i].id;
                            td.style.display = "none"
                            tr.appendChild(td);
                            var td = document.createElement('td');
                            td.innerHTML = itemList[i].licenseplate;
                            td.style.cursor = "pointer"
                            tr.appendChild(td);
                            if (!controller.minimalize) {
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].brand;
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].model;
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].type;
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].imei;
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                if (!controller.hideBackup)
                                {
                                    var td = document.createElement('td');
                                    td.innerHTML = itemList[i].backup_imei;
                                    td.style.cursor = "pointer"
                                    tr.appendChild(td);
                                }
                            }
                            var td = document.createElement('td');
                            td.innerHTML = itemList[i].main_arr_datetime;
                            if (itemList[i].main_arr_check == 0) { td.bgColor = "#D3D3D3"; }
                            else if (itemList[i].main_arr_check == 1) { td.bgColor = "#90EE90"; }
                            else if (itemList[i].main_arr_check == 2) { td.bgColor = "#FFFF66"; }
                            else
                            {
                                td.bgColor = "#FF4d4d";
                                td.style.color = "#FFFFFF";
                            }
                            td.style.cursor = "pointer"
                            tr.appendChild(td);
                            var td = document.createElement('td');
                            td.innerHTML = itemList[i].main_last_datetime;
                            if (itemList[i].main_last_check == 0) { td.bgColor = "#D3D3D3"; }
                            else if (itemList[i].main_last_check == 1) { td.bgColor = "#90EE90"; }
                            else if (itemList[i].main_last_check == 2) { td.bgColor = "#FFFF66"; }
                            else
                            {
                                td.bgColor = "#FF4d4d";
                                td.style.color = "#FFFFFF";
                            }
                            td.style.cursor = "pointer"
                            tr.appendChild(td);
                            if (!controller.hideBackup)
                            {
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].backup_arr_datetime;
                                if (itemList[i].backup_arr_check == -1)     { td.bgColor = "#FFFFFF"; }
                                else if (itemList[i].backup_arr_check == 0) { td.bgColor = "#D3D3D3"; }
                                else if (itemList[i].backup_arr_check == 1) { td.bgColor = "#90EE90"; }
                                else if (itemList[i].backup_arr_check == 2) { td.bgColor = "#FFFF66"; }
                                else {
                                    td.bgColor = "#FF4d4d";
                                    td.style.color = "#FFFFFF";
                                }
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].backup_last_datetime;
                                if (itemList[i].backup_last_check == -1)     { td.bgColor = "#FFFFFF"; }
                                else if (itemList[i].backup_last_check == 0) { td.bgColor = "#D3D3D3"; }
                                else if (itemList[i].backup_last_check == 1) { td.bgColor = "#90EE90"; }
                                else if (itemList[i].backup_last_check == 2) { td.bgColor = "#FFFF66"; }
                                else
                                {
                                    td.bgColor = "#FF4d4d";
                                    td.style.color = "#FFFFFF";
                                }
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                            }
                            if (!controller.minimalize) {
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].gps_check_datetime;
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                            }
                            if (!controller.hideBackup)
                            {
                                var td = document.createElement('td');
                                td.innerHTML = itemList[i].dist_main_backup;
                                if (itemList[i].dist_main_backup_check == -1)     { td.bgColor = "#FFFFFF"; }
                                else if (itemList[i].dist_main_backup_check == 0) { td.bgColor = "#D3D3D3"; }
                                else if (itemList[i].dist_main_backup_check == 1) { td.bgColor = "#90EE90"; }
                                else if (itemList[i].dist_main_backup_check == 2) { td.bgColor = "#FFFF66"; }
                                else
                                {
                                    td.bgColor = "#FF4d4d";
                                    td.style.color = "#FFFFFF";
                                }
                                td.style.cursor = "pointer"
                                tr.appendChild(td);
                                if (!controller.minimalize) {
                                    var td = document.createElement('td');
                                    td.innerHTML = itemList[i].dist_main_backup_datetime;
                                    td.style.cursor = "pointer"
                                    tr.appendChild(td);
                                }
                            }

                            tr.onclick = tableOnClickHandler(tr);
                            table.appendChild(tr);
                        }
                    }
                });
            }
        }
        angular.element(document).ready(function ()
        {
            if (localStorage.getItem('vehicleMonitorPageNumber'))
            {
                controller.pageNumber = +localStorage.getItem('vehicleMonitorPageNumber')

                if (window.isNaN(controller.pageNumber))
                {
                    controller.pageNumber = 1;
                }
            }
            if (localStorage.getItem('vehicleMonitorPageLimit'))
            {
                controller.pagesLimit = +localStorage.getItem('vehicleMonitorPageLimit')

                if (window.isNaN(controller.pagesLimit))
                {
                    controller.pagesLimit = 10;
                }

            }
            if (localStorage.getItem('vehicleMonitorMinimalize'))
            {
                controller.minimalize = (localStorage.getItem('vehicleMonitorMinimalize') === 'true')
            }

            if (localStorage.getItem('vehicleMonitorHideBackup'))
            {
                controller.hideBackup = (localStorage.getItem('vehicleMonitorHideBackup') === 'true')
            }

            if (localStorage.getItem('vehicleMonitorFilter'))
            {
                controller.filter = localStorage.getItem('vehicleMonitorFilter')
            }

            if (localStorage.getItem('vehicleMonitorShowOnlyRed'))
            {
                controller.showOnlyRed = (localStorage.getItem('vehicleMonitorShowOnlyRed') === 'true')
            }

            if (localStorage.getItem('vehicleMonitorOrdering'))
            {
                controller.ordering = localStorage.getItem('vehicleMonitorOrdering')
            }

            if (localStorage.getItem('vehicleMonitorOrderType'))
            {
                controller.orderType = localStorage.getItem('vehicleMonitorOrderType')
            }


            controller.loadTable();
            controller.getMaxCount();
        });
        var tableOnClickHandler = function(row) {
        return function() {
                var cell = row.getElementsByTagName("td")[0];
                var id = cell.innerHTML;
                document.location.href                                  = '/vehicle-monitoring/'+ id;
            };
        };
    }]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('TachoDownloadController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller = this;

		$scope.disableDownloadButton = true;

		controller.tachodownloadSubmit = function()
		{
			$http({
				url:'/tacho/start-download',
				method:'post',
				data:{
					vehicleId: controller.formData.vehicle,
					startDate: controller.formData.startdate,
					endDate: controller.formData.enddate,
					detailedSpeed: controller.formData.detailedSpeed,
					technicalData: controller.formData.technicalData,
					eventsAndFaults: controller.formData.eventsAndFaults,
					driverSlot: controller.formData.cardDownloadDriver,
					coDriverSlot: controller.formData.cardDownloadCoDriver,
					priorityDownload: controller.formData.priorityDownload
				}
			}).then(function(response)
			{
				if (response.data.error)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('tacho/error'))
							.textContent(translate('tacho/error/download'))
							.ok('OK'));
				}
				else if (response.data.data.items[0])
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('tacho/error'))
							.textContent(response.data.data.items[0])
							.ok('OK'));
				}
				else
				{
					reload();
				}
			});
		};

		controller.tachodownloadAbort = function(requestId, vehicleId, priorityDownload)
		{
			$http({
				url:'/tacho/stop-download',
				method:'post',
				data:{
					requestId: requestId,
					vehicleId: vehicleId,
					priorityDownload: priorityDownload
				}
			}).then(function(response)
			{
				if (response.data.error)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('tacho/error'))
							.textContent(translate('tacho/error/stopDownload'))
							.ok('OK'));
				}
				else
				{
					reload();
				}
			});
		};

		$scope.$watch('controller.formData.vehicle', validateDownloadFields('controller.formData.vehicle'));

		$scope.$watch('controller.formData.startdate', validateDownloadFields('controller.formData.startdate'));

		$scope.$watch('controller.formData.enddate', validateDownloadFields('controller.formData.enddate'));

		function validateDownloadFields(expr)
		{
			return function(newVal, oldVal)
			{
				if ((controller.formData.vehicle === undefined || controller.formData.vehicle === null)
					|| (controller.formData.startdate === undefined || controller.formData.startdate === null)
					|| (controller.formData.enddate === undefined || controller.formData.enddate === null))
				{
					$scope.disableDownloadButton = true;
				}
				else
				{
					$scope.disableDownloadButton = false;
				}
			}
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DeviceController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', '$controller', '$attrs', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout, $controller, $attrs)
	{
		var controller = angular.extend(this, $controller('EntityFormController', {$scope: $scope, $attrs: $attrs, $element: $element}));

		controller.immobilizerSubmit = function(parentUrl, state)
		{
			if (state == 'immobilizer_on')
			{
				$mdDialog.show(
						$mdDialog.confirm()
						.title(translate('keyword/confirm'))
						.htmlContent(translate('immobilizer/warning/text'))
						.ok(translate('keyword/ok'))
						.cancel(translate('keyword/cancel'))
					).then(function()
					{
						immobilizerRequest(parentUrl, state);
					})
			}
			else
			{
				immobilizerRequest(parentUrl, state);
			}
		};

		function immobilizerRequest(parentUrl, state)
		{
			$http({
				url:parentUrl + '/immobilizer?state=' + state,
				method:'get'
			}).then(function(response)
			{
				reload();
			});
		};

		$scope.deviceCallback = function(items)
		{
			controller.formData.serialnumber = items[0].serialnumber;
			controller.formData.manufacturer = items[0].manufacturer;
			controller.formData.device_type_name = items[0].device_type_name;
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DataDeviceController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', '$controller', '$attrs', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout, $controller, $attrs)
	{
		var controller = angular.extend(this, $controller('EntityFormController', {$scope: $scope, $attrs: $attrs, $element: $element}));

		$scope.deviceCallback = function(items)
		{
			controller.formData.data_device_manufacturer = items[0].manufacturer;
			controller.formData.data_device_type_name = items[0].device_type_name;
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('VehicleController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller															= this;

		controller.immobilizerSubmit = function(state, formcontroller)
		{
			if (state == 'immobilizer_on')
			{
				$mdDialog.show(
						$mdDialog.confirm()
						.title(translate('keyword/confirm'))
						.htmlContent(translate('immobilizer/warning/text'))
						.ok(translate('keyword/ok'))
						.cancel(translate('keyword/cancel'))
					).then(function()
					{
						formcontroller.submit(state);
					})
			}
			else
			{
				formcontroller.submit(state);
			}
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DriverController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller															= this;

		controller.updateScopes = function(driverId, type, event)
		{
			var scopes = [];

			if (event.target.checked)
			{
				scopes.push(type);
			}

			$mdDialog.show(
					$mdDialog.confirm(
						      {
						    	  onRemoving: (function() { event.target.checked = !event.target.checked; }),
						      })
					.title(translate('keyword/confirm'))
					.htmlContent(translate('keyword/blockedscopes/warning'))
					.ok(translate('keyword/ok'))
					.cancel(translate('keyword/cancel'))
				).then(function()
				{
					event.target.checked = !event.target.checked;
					$http({
						url:  '/drivers/'+ driverId + '/scopes/change',
						method: 'post',
						data:scopes
					}).then(function(response)
					{
						reload();
					})
				}
			);
		}
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ExportController',  ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', '$controller', '$attrs', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout, $controller, $attrs)
	{
		var controller = angular.extend(this, $controller('EntityFormController', {$scope: $scope, $attrs: $attrs, $element: $element}));

		controller.createExportVehicleMonitor = function()
		{
			$http({
				url: '/exports/create-vehicle-monitor-export',
				method: 'post',
				data: {
					'filename':controller.formData.filename,
					'tags': $scope.lookupModel1 ? controller.formData.vehicle_tag_ids: [],
					'anonymous': controller.formData.anonymous
					}
			}).then(function(response)
			{
				if (response.data.error)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(false)
							.title('Export')
							.htmlContent(translate(response.data.error.error))
							.ok('OK')
					)
				}
				else
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title('Export')
							.htmlContent('Item added to queue')
							.ok('OK')
					)
				}
			});
		};
		$scope.tagsCallback = function(items)
		{
			var vehicleTagIds = [];

			if (items != null)
			{
				angular.forEach(items, function(item) {
					vehicleTagIds.push(item.id)
				})
			}

			controller.formData.vehicle_tag_ids = vehicleTagIds;
		}
	}]);
})();

(function()
{
	'use strict';

	angular.module('app').controller('MainController', ['$scope', '$mdDialog', '$http', 'LookupService', '$element', function($scope, $mdDialog, $http, LookupService, $element)
	{
		var mainController														= this;

		var setService = function(service, save)
		{
			mainController.serviceId											= service.id;
			mainController.serviceName 											= service.servicename;
			mainController.flavour												= service.flavour;
			mainController.parentCompanyId                                      = service.parent_company_id;

			if (save !== false)
			{
				try																{ window.localStorage.service = JSON.stringify(service); window.location.hash = '/';}
				catch (ex)														{ /* no problem */ }
			}
		}

		try
		{
			var service;

			if (window.localStorage.service !== undefined && (service = JSON.parse(window.localStorage.service)) != null && service.parent_company_id == $element.attr('data-service-parent_company_id'))
			{
			    setService(service, false);
			}
			else
			{
				mainController.serviceId										= $element.attr('data-service-id');
				mainController.serviceName										= $element.attr('data-service-name');
				mainController.flavour											= $element.attr('data-service-flavour');
				mainController.parentCompanyId                                  = $element.attr('data-service-parent_company_id');

				service															= {'id': mainController.serviceId, 'servicename': mainController.serviceName, 'flavour':mainController.flavour, 'parent_company_id':mainController.parentCompanyId};
				setService(service, true);
			}
		}
		catch (ex)																{ /* no problem */ }

		setGlobalAjaxHeader('X-Service-ID', function()
		{
			return mainController.serviceId ? mainController.serviceId : '';
		});

		setGlobalAjaxHeader('X-Service-Flavour', function()
		{
			return mainController.flavour ? mainController.flavour : '';
		});

		var serviceSet = false;

		$scope.$watch('mainController.serviceId', function(newValue)
		{
			if (serviceSet)
			{
				if (newValue)													{ reload(); }
			}
			else																{ serviceSet = true; }
		});

		mainController.getService = function()
		{
			var LookupController = function($scope, $mdDialog, $http, $compile, $q, $util, $timeout, LookupService, $parse)
			{
				var controller													= this;

				controller.formData												= {};

				controller.select = function()
				{
					$scope.childTableControllers[0].getSelectedRows().then(function(rows)
					{
						if (rows.length > 0)									{ $mdDialog.hide(rows); }
					});
				}

				controller.cancel = function()
				{
					$mdDialog.cancel();
				};

				$scope.$on('table.onToggleSelection', function(e, b, row, tableController)
				{
					if (b && tableController.getOption('singleSelectionMode'))
					{
						tableController.getSelectedRows().then(function(rows)
						{
							$mdDialog.hide(rows);
						});
					}
				});
			};

			LookupController.$inject											= [ '$scope', '$mdDialog', '$http', '$compile', '$q', '$util', '$timeout', 'LookupService', '$parse' ];

			$mdDialog.show({
				'templateUrl': '/service-lookup',
				'controller': LookupController,
				'controllerAs': 'controller',
				'skipHide': true, // Angular 1.5.8
				'multiple': true // Angular 1.5.9+
			}).then(function(rows) { setService(rows[0], true); });
		};
	}]);
})();

